import * as React from "react";
import Avatar, { AvatarProps } from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core";
import { getIcon } from "./util";
import { ThreadTypes } from "../../types";

interface Props {
  type: ThreadTypes;
}

const useStyles = makeStyles({
  avatar: {
    width: 30,
    height: 30,
  },
});

export default ({ type, ...props }: Props & (AvatarProps | any)) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar} src={getIcon(type)} {...(props || {})} />
  );
};
