import { Box, Button, Dialog, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import { Message } from "../../models";
import { MessagePartial, ThreadTags } from "../../types";
import { VisibilityRadio } from "../VisibilityRadio";
import CurrentUserContext from "../../util/CurrentUserContext";

interface IEditMessageModalProps {
  open: boolean;
  message: Message;
  onSubmit: (
    id: string | number,
    message: Pick<MessagePartial, "tags" | "body">
  ) => void;
  onClose: () => void;
}

const EditMessageModal: React.FunctionComponent<IEditMessageModalProps> = ({
  open,
  message,
  onSubmit,
  onClose,
}) => {
  const [body, setBody] = React.useState<string>(message.body);
  const [tags, setTags] = React.useState<ThreadTags[]>(message.tags ?? []);
  const currentUser = React.useContext(CurrentUserContext)[0];
  const currentUserIsPM = currentUser === "pm";

  React.useEffect(() => {
    setBody(message.body);
    setTags(message.tags ?? []);
  }, [message, open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding={3} display="flex" flexDirection="column">
        <Typography>Edit Comment</Typography>
        <TextField
          value={body}
          placeholder="Additional notes..."
          multiline
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            if (value.length < 155 || currentUserIsPM) {
              setBody(value);
            }
          }}
          helperText={currentUserIsPM ? null : `${body.length}/154`}
        />

        <VisibilityRadio appliedTags={tags} setAppliedTags={setTags} />
        <Button onClick={handleSubmit}>Submit</Button>
      </Box>
    </Dialog>
  );

  function handleSubmit() {
    onSubmit(message.id, {
      body,
      tags,
    });

    onClose();
  }
};

export default EditMessageModal;
