import { Box, Button, Dialog, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import { ThreadController, ThreadTags, ThreadTypes } from "../../types";
import { VisibilityRadio } from "../VisibilityRadio";
import CurrentUserContext from "../../util/CurrentUserContext";

interface IEditThreadModalProps {
  open: boolean;
  thread: ThreadController;
  onClose: () => void;
}

const EditThreadModal: React.FunctionComponent<IEditThreadModalProps> = ({
  open,
  thread,
  onClose,
}) => {
  const [body, setBody] = React.useState<string>(thread.name);
  const [tags, setTags] = React.useState<ThreadTags[]>(thread.tags ?? []);
  const currentUser = React.useContext(CurrentUserContext)[0];
  const currentUserIsPM = currentUser === "pm";

  React.useEffect(() => {
    setBody(thread.name);
    setTags(thread.tags ?? []);
  }, [thread, open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding={3} display="flex" flexDirection="column">
        <Typography>Edit thread</Typography>
        <TextField
          value={body}
          placeholder="Additional notes..."
          multiline
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            if (value.length < 155 || currentUserIsPM) {
              setBody(value);
            }
          }}
          helperText={currentUserIsPM ? null : `${body.length}/154`}
        />
        {thread.type === ThreadTypes.PM && (
          <VisibilityRadio appliedTags={tags} setAppliedTags={setTags} />
        )}
        <Button onClick={handleSubmit}>Submit</Button>
      </Box>
    </Dialog>
  );

  function handleSubmit() {
    const newTags = tags.includes(ThreadTags.NOT_CLIENT_VISIBLE)
      ? [...thread.tags, ...tags]
      : thread.tags.filter((t) => t !== ThreadTags.NOT_CLIENT_VISIBLE);

    thread.merge({
      name: body,
      tags: newTags,
    });

    thread.updateUi();
    onClose();
  }
};

export default EditThreadModal;
