import * as React from "react";
import { ThreadTypes } from "../../types";
import { Divider, makeStyles } from "@material-ui/core";
import IconAvatar from "./IconAvater";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  iconContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    transition: "background-color 200ms",
    padding: "1rem 0",
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
  },
}));

export interface Props {
  onSelectType: (type: ThreadTypes) => void;
  selected: TypesSelected;
}

export interface TypesSelected {
  love?: boolean;
  remove?: boolean;
  change?: boolean;
  question?: boolean;
}

export default (props: Props) => {
  const classes = useStyles();
  return (
    <div>
      <Divider />
      <div className={classes.root}>
        <div
          className={classnames(
            classes.iconContainer,
            props.selected.love ? classes.selected : ""
          )}
          onClick={() => props.onSelectType(ThreadTypes.LOVE)}
        >
          <IconAvatar
            type={ThreadTypes.LOVE}
            style={{ height: "40px", width: "40px" }}
          />
        </div>
        <div
          className={classnames(
            classes.iconContainer,
            props.selected.change ? classes.selected : ""
          )}
          onClick={() => props.onSelectType(ThreadTypes.CHANGE)}
        >
          <IconAvatar
            style={{ height: "40px", width: "40px" }}
            type={ThreadTypes.CHANGE}
          />
        </div>
        <div
          className={classnames(
            classes.iconContainer,
            props.selected.question ? classes.selected : ""
          )}
          onClick={() => props.onSelectType(ThreadTypes.QUESTION)}
        >
          <IconAvatar
            style={{ height: "40px", width: "40px" }}
            type={ThreadTypes.QUESTION}
          />
        </div>
        <div
          className={classnames(
            classes.iconContainer,
            props.selected.remove ? classes.selected : ""
          )}
          onClick={() => props.onSelectType(ThreadTypes.REMOVE)}
        >
          <IconAvatar
            style={{ height: "40px", width: "40px" }}
            type={ThreadTypes.REMOVE}
          />
        </div>
      </div>
    </div>
  );
};
