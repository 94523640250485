import * as React from "react";
import { makeStyles, Typography, IconButton, Avatar } from "@material-ui/core";
import { ArrowRight, Delete, Edit } from "@material-ui/icons";
import { ThreadController, ThreadTags, ThreadTypes } from "../../types";
import IconAvatar from "./IconAvater";
import classnames from "classnames";
import ConfirmDeleteThread from "./ConfirmDeleteThread";
import CurrentUserContext from "../../util/CurrentUserContext";
import cfIcon from "../../icons/ClientFacing.png";
import dfIcon from "../../icons/DesignerFacing.png";
import EditThreadModal from "./EditThreadModal";

interface Props {
  Icon?: any;
  thread: ThreadController;
  selected?: boolean;
  onDelete: () => void;
  onEdit: () => void;
  onClick: (e: React.MouseEvent | any) => any;
  appliedTags: string[];
  readonly: boolean;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "inherit",
    maxWidth: "inherit",
    padding: "0.3rem 0.5rem",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    pointerEvents: "all",
  },
  arrow: {
    transition: "transform 300ms",
    width: "40px",
    height: "40px",
  },
  arrowTilted: {
    transform: "rotate(90deg)",
  },
  font: {
    fontFamily: '"Raleway", sans-serif',
  },
});

export const ThreadTitleBox = (props: Props) => {
  const avRef = React.useRef();
  const classes = useStyles();
  const mCount = props.thread.messages.length;
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [currentUser, userId] = React.useContext(CurrentUserContext);

  return !confirmDelete ? (
    <div className={classes.root} onClick={props.onClick}>
      <EditThreadModal
        thread={props.thread}
        open={props.thread.editMode}
        onClose={() => {
          props.thread.editMode = false;
        }}
      />
      <IconAvatar type={props.thread.type} ref={avRef as any} />
      {props.thread.type === ThreadTypes.PM && currentUser === "pm" && (
        <Avatar
          style={{
            width: "1.25rem",
            height: "1.25rem",
            position: "relative",
            top: "11px",
            right: "9px",
          }}
          src={
            props.thread.tags.includes(ThreadTags.NOT_CLIENT_VISIBLE)
              ? dfIcon
              : cfIcon
          }
        />
      )}
      <div
        style={{
          position: "absolute",
          width: "fit-content",
          height: "fit-content",
          zIndex: 5,
          color: "red",
          display: avRef.current && mCount ? "block" : "none",
          borderRadius: "100%",
          top: avRef.current
            ? (avRef.current as unknown as HTMLElement).getBoundingClientRect()
                .top
            : 0,
          left: avRef.current
            ? (avRef.current as unknown as HTMLElement).getBoundingClientRect()
                .left
            : 0,
        }}
      >
        <Typography style={{ color: "white" }} className={classes.font}>
          {mCount}
        </Typography>
      </div>
      <div
        style={{
          flexGrow: 1,
          paddingLeft:
            props.thread.type !== ThreadTypes.PM
              ? "0.8rem"
              : props.selected && currentUser === "pm"
              ? undefined
              : "0.8rem",
        }}
      >
        <Typography className="classes.font">
          {(() => {
            if (!props.selected) {
              return props.thread.name.length < 25
                ? props.thread.name
                : props.thread.name.substr(0, 25).trim() + "...";
            } else return getTypeTitle(props.thread.type);
          })()}
        </Typography>
      </div>
      <span
        style={{
          width: "fit-content",
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.selected && (
          <>
            {(currentUser === "pm" ||
              (userId === props.thread.authorId && !props.readonly)) && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  props.onEdit();
                }}
                size="small"
              >
                <Edit />
              </IconButton>
            )}
            {(currentUser === "pm" ||
              (userId === props.thread.authorId && !props.readonly)) && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDelete(true);
                }}
                size="small"
              >
                <Delete />
              </IconButton>
            )}
          </>
        )}
        <ArrowRight
          className={classnames(
            classes.arrow,
            props.selected ? classes.arrowTilted : ""
          )}
        />
      </span>
    </div>
  ) : (
    <ConfirmDeleteThread
      onClose={() => setConfirmDelete(false)}
      onConfirm={() => {
        props.onDelete();
        setConfirmDelete(false);
      }}
    />
  );

  function getTypeTitle(type: ThreadTypes): string {
    switch (type) {
      case ThreadTypes.LOVE:
        return "Love it!";
      case ThreadTypes.CHANGE:
        return "Change this!";
      case ThreadTypes.REMOVE:
        return "Remove this!";
      case ThreadTypes.QUESTION:
        return "I Have a Question...";
      case ThreadTypes.PM:
        return props.thread.author;
      default:
        return "";
    }
  }
};
