import {
  DatabaseController,
  SerializedThread,
  ThreadController,
  SerializedEntity,
  Drawable,
  ThreadTypes,
  Thread,
  ThreadTags,
} from "../types";
import firebase from "firebase/compat";
import "firebase/compat/firestore";
import { FirebaseThreadController } from "./FirebaseThreadController";
import { CurrentUser } from "../util/CurrentUserContext";
import { useMediaDomain } from "./settings";

export interface ProfileProps {
  firstName: string;
  lastName: string;
}

export interface FirebaseControllerConfig {
  mediaId: string;
  userId: string;
  profileProps: ProfileProps;
  currentUser: CurrentUser;
}

export class FirebaseController extends DatabaseController {
  private _onChange?: () => void;
  private _mediaId: string;
  private _userName: string;
  private _mediaURL?: string;
  // private _lastSnapshot?: firebase.firestore.CollectionReference;
  private _userId: string;
  private _unSubscribeHandlers: (() => void)[] = [];
  private currentUser: CurrentUser;
  public editMode: boolean = false;

  constructor(config: FirebaseControllerConfig) {
    super();
    const props = config.profileProps;

    this._userName = props.firstName;
    this._mediaId = config.mediaId;

    this.currentUser = config.currentUser;
    this._userId = config.userId;
    this._initializedPromise = this._initialize(config);
  }

  public destroy() {
    for (let unsub of this._unSubscribeHandlers) unsub();
    for (let thread of this.threads as FirebaseThreadController[])
      thread.destroy();
  }

  public isReady(): Promise<any> | boolean | undefined {
    if (this._initialized) return true;
    else return this._initializedPromise;
  }

  public setEditMode(yes: boolean) {
    this.editMode = yes;
    this.updateUi();
  }

  public getSrc = () => this._mediaURL as string;

  protected async _initialize(config: FirebaseControllerConfig): Promise<any> {
    this._mediaId = config.mediaId;

    await this._initMediaDoc();
  }

  public get src(): string {
    if (!!this._mediaURL) return this._mediaURL;
    throw new Error(
      "media url not found," + "did you initialize the firebase controller?"
    );
  }

  private async _getMediaSrc(path: string): Promise<string> {
    if (useMediaDomain) {
      return this._getMediaSrcFromMediaDomain(path);
    }
    return await this._getMediaSrcFromFirebase(path);
  }

  private _getMediaSrcFromMediaDomain(path: string): string {
    return `${process.env["REACT_APP_IMAGE_DOMAIN"]}/${path}`;
  }

  private async _getMediaSrcFromFirebase(path: string): Promise<string> {
    try {
      return firebase.storage().ref(path).getDownloadURL();
    } catch (err) {
      console.error("failed to get url for media");
      throw err;
    }
  }

  protected async _initMediaDoc() {
    if (!this._mediaId)
      throw new Error("tried to access doc without revision id");

    const doc = await firebase
      .firestore()
      .collection("media")
      .doc(`${this._mediaId}`);

    const data = (await doc.get()).data() as firebase.firestore.DocumentData;

    if (!data) throw new Error("could not find or access media document");

    this._mediaURL = await this._getMediaSrc(data.path);

    let unsub = doc.collection("threads").onSnapshot((change) => {
      const newDocs = change.docChanges();

      if (!newDocs.length) {
        return;
      }

      for (const d of newDocs) {
        if (
          !d.doc.data().name ||
          !this.threadIsVisible(d.doc.data() as SerializedThread)
        ) {
          continue;
        }

        if (d.type === "removed") {
          const i = (this.threads as FirebaseThreadController[]).findIndex(
            (t) => d.doc.id === t.id
          );

          (this.threads as FirebaseThreadController[]).splice(i, 1);
          return this.updateUi();
        } else if (d.type === "modified") {
          const i = (this.threads as FirebaseThreadController[]).findIndex(
            (t) => d.doc.id === t.id
          );

          this._threads.splice(
            i,
            1,
            new FirebaseThreadController(
              d.doc.id,
              this._mediaId as string,
              this._userId,
              this._userName as string,
              d.doc.data() as SerializedThread,
              this.currentUser
            )
          );
        } else if (d.type === "added") {
          this._threads.push(
            new FirebaseThreadController(
              d.doc.id,
              this._mediaId as string,
              this._userId,
              this._userName as string,
              d.doc.data() as SerializedThread,
              this.currentUser
            )
          );
        }
      }
      this.updateUi();
    });

    this._unSubscribeHandlers.push(unsub);
  }

  public updateUi = (): void => {
    const ids: string[] = [];
    (this._threads as FirebaseThreadController[]).forEach((t, i) => {
      if (ids.includes(t.id)) {
        this._threads.splice(i, 1);
        return;
      }
      ids.push(t.id);
    });
    if (this._onChange) {
      this._onChange();
    }
  };

  public set onChange(cb: () => void) {
    this._onChange = cb;
  }

  /*
    Checks yzWebRoles against thread.tags.
    Currently only looks for client-excluded threads.
  */
  private threadIsVisible(thread: Thread | SerializedThread): boolean {
    const tags = thread.tags ?? [];

    if (tags.includes(ThreadTags.NOT_CLIENT_VISIBLE)) {
      return this.currentUser === "pm" || this.currentUser === "agent";
    }

    return true;
  }

  protected _newThreadId(): string {
    let max: number = -Infinity;

    if (this.threads.length < 1) return "1";

    for (let { id } of this._threads as FirebaseThreadController[]) {
      const nid = parseInt(id);
      if (nid > max) {
        max = nid;
      }
    }

    return (max + 1).toString();
  }

  protected deserializeThreads = (
    threads: (SerializedThread & { id: number | string; mediaId: string })[]
  ): ThreadController[] => {
    return threads.map((t) => {
      const { id, ...rest } = t;
      return new FirebaseThreadController(
        id.toString(),
        this._userId as string,
        this._mediaId as string,
        this._userName as string,
        rest,
        this.currentUser
      );
    });
  };

  public newThread(
    entity: SerializedEntity & Drawable,
    type: ThreadTypes,
    name: string,
    tags = []
  ): FirebaseThreadController {
    const newThread = new FirebaseThreadController(
      this._newThreadId().toString(),
      this._mediaId as string,
      this._userId,
      this._userName as string,
      {
        entity,
        author: this._userName as string,
        authorId: this._userId,
        messages: [],
        tags,
        type,
        name,
      },
      this.currentUser
    );

    this._threads.push(newThread.save());
    return newThread;
  }
}
