import * as React from "react";
import {
  Typography,
  Paper,
  Grow,
  Button,
  makeStyles,
  TextField,
  IconButton,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ThreadTags, ThreadTypes } from "../../types";
import classnames from "classnames";
import IconAvatar from "../MuiCommentPane/IconAvater";
import { VisibilityRadio } from "../VisibilityRadio";
import CurrentUserContext from "../../util/CurrentUserContext";

interface Props {
  onClick: (type: ThreadTypes, name: string) => void;
  onClose: () => void;
  open: boolean;
  top: number;
  left: number;
  message?: string;
  appliedTags: ThreadTags[];
  setAppliedTags: (tags: ThreadTags[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
  },
  paper: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  font: {
    fontFamily: '"Raleway", sans-serif',
  },
  button: {
    margin: "0 1rem",
  },
  message: {
    FontSize: "1.3rem",
  },
  errorMessage: {
    width: "fit-content",
    borderBottom: `solid 1px ${theme.palette.error.main}`,
    padding: "0 8px",
    margin: "auto",
    marginBottom: "4px",
  },
  container: {
    zIndex: 6,
    position: "absolute",
    width: "fit-content",
    height: "fit-content",
  },
  submit: {
    borderRadius: "inherit",
    margin: "0.8rem",
    color: "black",
  },
  buttonContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
  },
  textArea: {
    flexGrow: 1,
    margin: "0.5rem auto",
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  italic: {
    fontStyle: "italic",
  },
  bold: {
    fontWeight: "bold",
  },
  input: {},
}));

export const PopUp = (props: Props) => {
  const classes = useStyles();
  const currentUser = React.useContext(CurrentUserContext)[0];
  const [type, setType] = React.useState<ThreadTypes | null>(
    currentUser === "pm" ? ThreadTypes.PM : null
  );
  const [name, setName] = React.useState<string>("");
  const [nameError, setNameError] = React.useState<string | undefined>(
    undefined
  );
  const [typeError, setTypeError] = React.useState<string | undefined>();

  const ref = React.useRef(null);
  const inRef = React.useRef<HTMLInputElement | null>(null);

  const smallerScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  React.useEffect(() => {
    if (inRef.current && inRef.current.focus && !smallerScreen) {
      inRef.current.focus();
    }

    if (!props.open) {
      setName("");
      setType(currentUser === "pm" ? ThreadTypes.PM : null);
      setNameError(undefined);
    }

    if (!props.open && inRef.current) {
      inRef.current.blur();
    }
  }, [props.open]);

  React.useEffect(() => {
    if (inRef.current) {
      inRef.current.onfocus = handleIpadScroll;
    }
  }, [inRef.current]);

  React.useEffect(() => {
    if (!!type) setTypeError(undefined);
  }, [type]);

  const xy = React.useMemo(getXY, [props.top + props.left]);

  return (
    <>
      <div
        className={classes.container}
        style={{
          top: xy[1],
          left: xy[0],
          opacity: props.open && props.message ? 1 : 0,
          zIndex: props.open && props.message ? 6 : -1,
        }}
      >
        <Grow in={props.open && !!props.message}>
          <Paper
            style={{
              padding: "0.5rem 0.5rem",
              maxWidth: "340px",
              zIndex: props.open && props.message ? 6 : -1,
              opacity: props.open && props.message ? 1 : 0,
              display: props.open && props.message ? "block" : "none",
              ...(props.open && props.message
                ? {}
                : {
                    position: "absolute",
                    bot: -100,
                    left: -100,
                  }),
            }}
          >
            <Typography
              className={classnames(classes.font, classes.message)}
              variant="body2"
            >
              {props.message}
            </Typography>
          </Paper>
        </Grow>
      </div>
      <div
        style={{
          display: !props.message && props.open ? "inline-block" : "none",
          position: "absolute",
          minHeight: "24px",
          minWidth: "24px",
          borderRadius: "100%",
          backgroundColor: "white",
          transition: "opacity 200ms",
          opacity: props.open && !props.message ? 1 : 0,
          left: props.left - 12,
          top: props.top - 12,
          zIndex: props.open ? 7 : -1,
        }}
      />
      <form
        className={classes.container}
        onSubmit={onSubmit}
        style={{
          top: xy[1],
          left: xy[0],
          opacity: props.open && !props.message ? 1 : 0,
          zIndex: props.open && !props.message ? 6 : -1,
        }}
        ref={ref}
      >
        <Grow in={props.open && !props.message}>
          <Paper
            className={classes.paper}
            style={{
              zIndex: props.open && !props.message ? 6 : -1,
              opacity: props.open && !props.message ? 1 : 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "space-between",
                padding: "0.8rem",
                alignItems: "center",
              }}
            >
              <TextField
                label="Comment"
                id="annotator-popup-input"
                className={classnames(classes.textArea, classes.font)}
                value={name}
                inputRef={inRef}
                error={!!nameError}
                multiline
                helperText={getHelperText}
                onChange={({ target: { value } }) => {
                  if (currentUser === "pm" || value.length < 155) {
                    if (nameError) {
                      setNameError(undefined);
                    }
                    setName(value);
                  }
                }}
                inputProps={{ className: classes.input }}
              />
              <IconButton
                size="small"
                onClick={props.onClose}
                style={{ height: 35, width: 35, margin: "0.25rem" }}
              >
                <Close />
              </IconButton>
            </div>
            {currentUser === "pm" && (
              <VisibilityRadio
                appliedTags={props.appliedTags}
                setAppliedTags={props.setAppliedTags}
              />
            )}
            {currentUser !== "pm" && (
              <div>
                <span
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      title="Love it!"
                      className={classnames(
                        classes.button,
                        type === ThreadTypes.LOVE ? classes.selectedButton : ""
                      )}
                      color="primary"
                      onClick={() => onClick(ThreadTypes.LOVE)}
                    >
                      <IconAvatar type={ThreadTypes.LOVE} />
                    </Button>
                    <Typography
                      variant="caption"
                      className={classnames(classes.font, classes.italic)}
                    >
                      Love it!
                    </Typography>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      title="Change this!"
                      className={classnames(
                        classes.button,
                        type === ThreadTypes.CHANGE
                          ? classes.selectedButton
                          : ""
                      )}
                      color="primary"
                      onClick={() => onClick(ThreadTypes.CHANGE)}
                    >
                      <IconAvatar type={ThreadTypes.CHANGE} />
                    </Button>
                    <Typography
                      variant="caption"
                      className={classnames(classes.font, classes.italic)}
                    >
                      Change this!
                    </Typography>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button
                      title="I have a question!"
                      variant="outlined"
                      className={classnames(
                        classes.button,
                        type === ThreadTypes.QUESTION
                          ? classes.selectedButton
                          : ""
                      )}
                      color="primary"
                      onClick={() => onClick(ThreadTypes.QUESTION)}
                    >
                      <IconAvatar type={ThreadTypes.QUESTION} />
                    </Button>
                    <Typography
                      variant="caption"
                      className={classnames(classes.font, classes.italic)}
                    >
                      Question?
                    </Typography>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      title="Remove this!"
                      className={classnames(
                        classes.button,
                        type === ThreadTypes.REMOVE
                          ? classes.selectedButton
                          : ""
                      )}
                      color="primary"
                      onClick={() => onClick(ThreadTypes.REMOVE)}
                    >
                      <IconAvatar type={ThreadTypes.REMOVE} />
                    </Button>
                    <Typography
                      variant="caption"
                      className={classnames(classes.font, classes.italic)}
                    >
                      Remove this!
                    </Typography>
                  </div>
                </span>
              </div>
            )}

            {typeError && (
              <Typography
                variant="caption"
                color="error"
                align="center"
                className={classnames(
                  classes.errorMessage,
                  classnames(classes.font)
                )}
              >
                {typeError}
              </Typography>
            )}
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              className={classnames(classes.font, classes.submit)}
            >
              Submit
            </Button>
          </Paper>
        </Grow>
      </form>
    </>
  );

  function getXY(): number[] {
    if (!ref.current) {
      return [props.left, props.top];
    }

    if (props.message) {
      return [props.left + 30, props.top - 20 >= 0 ? props.top - 20 : 0];
    }

    let { height, width } = (
      ref.current as unknown as HTMLDivElement
    ).getBoundingClientRect();

    let left = props.left - width / 2;
    let top = props.top - height - 30;

    if (left < 0) {
      left = 0;
    }

    if (top < 10) {
      top = props.top + 30;
    }

    return [left, top];
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (type === null) {
      return setTypeError("Please select an annotation type.");
    }

    if (name.length < 1) {
      return setNameError(
        "Please provide some information about your Annotation."
      );
    }

    inRef?.current?.blur();
    setTimeout(() => {
      props.onClick(type, name);
    }, 200);
  }

  function onClick(_type: ThreadTypes) {
    if (!props.open) {
      return;
    }

    if (_type === type) {
      setType(null);
      return;
    }

    setType(_type);

    if (typeError) {
      setTypeError(undefined);
    }
  }

  function handleIpadScroll(e: any) {
    console.log(e);
    // e.preventDefault();
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
  }

  function getHelperText() {
    if (currentUser !== "pm") {
      return (!name.length && nameError) || `${name.length}/154`;
    } else {
      return (!name.length && nameError) || null;
    }
  }
};
