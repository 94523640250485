import * as React from "react";
import { Controller } from "./Controller";
import { DatabaseController, ThreadController } from "./types";

export interface AnnotatorProps {
  canvasProps?: any;
  style?: { [key: string]: any };
  containerProps?: any;
  dataController: DatabaseController;
  id?: string;
  readonly?: boolean;
  onReady: (callback: () => Controller) => any;
  onSelectThread: (thread: ThreadController | undefined) => any;
  onClick: (e: MouseEvent, thread?: ThreadController) => void;
}

interface Ready {
  image: boolean;
  divRef: boolean;
  canvasRef: boolean;
}

function _isReady(tracker: Ready): boolean {
  return tracker.image && tracker.divRef && tracker.canvasRef;
}

function prepareImage(src: string | Blob): Promise<HTMLImageElement> {
  let url: string;

  if (src instanceof Blob) url = window.URL.createObjectURL(src);
  else url = src;

  const image = new Image();
  image.style.display = "none";
  document.body.appendChild(image);

  return new Promise<HTMLImageElement>((resolve) => {
    image.onload = () => resolve(image);
    image.src = url;
  });
}

export const Annotator = (props: AnnotatorProps) => {
  const divRef = React.useRef<any>(null);
  const canvasRef = React.useRef<any>(null);
  const [ready, setReady] = React.useState<Ready>({
    image: false,
    divRef: false,
    canvasRef: false,
  });
  const [image, setImage] = React.useState<HTMLImageElement | null>(null);
  const [annotatorOn, setAnnotatorOn] = React.useState<boolean>(false);
  const [controller, setController] = React.useState<Controller | null>(null);
  const isReady = _isReady(ready);

  if (controller) controller.sizeCanvas();

  React.useEffect(() => {
    // if (!typeGuardAnnotatorProps(props))
    //   throw new Error('incompatible props passed to Annotator');

    (async function () {
      setImage(await prepareImage(props.dataController.getSrc()));
    })();

    return () => {
      if (controller) controller.destroy();
    };
  }, []);

  React.useEffect(() => {
    if (isReady) return;

    if (!ready.image && image) setReady({ ...ready, image: !!image });
    if (!ready.canvasRef && canvasRef.current)
      setReady({ ...ready, canvasRef: !!canvasRef });
    if (!ready.divRef && divRef.current)
      setReady({ ...ready, divRef: !!divRef });
  });

  React.useEffect(() => {
    if (isReady) setAnnotatorOn(true);
  }, [isReady]);

  React.useEffect(() => {
    if (!isReady) return;
    const controller = new Controller(
      props.dataController,
      canvasRef,
      divRef,
      image as HTMLImageElement,
      props.readonly
    );
    controller.onSelect = props.onSelectThread;
    setController(controller);
    props.onReady(() => {
      controller.sizeCanvas();
      return controller as Controller;
    });
  }, [annotatorOn]);

  return (
    <div
      {...props.containerProps}
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: "100vw",
        maxHeight: "inherit",
        flexGrow: 1,
        ...(!!(props.containerProps && props.containerProps.style)
          ? props.containerProps.style
          : {}),
        ...(props.style ? props.style : {}),
      }}
      ref={divRef}
    >
      <canvas
        {...(props.canvasProps as any | {})}
        {...(controller ? controller.getHandlers() : {})}
        onClick={(e: MouseEvent) => {
          if (!controller) return;

          try {
            (e as any).persist();
          } catch {
          } finally {
            const thread = controller.isOverPoint(e.clientX, e.clientY);
            props.onClick(e, thread);
          }
        }}
        ref={canvasRef}
        id={props.id}
        style={{
          ...((props.canvasProps && (props.canvasProps.style as any)) || {}),
          width: "inherit",
          cursor: "crosshair",
          height: "inherit",
        }}
      />
    </div>
  );
};
