import * as React from "react";

export type CurrentUser = "pm" | "agent" | "client";

interface CurrentUserProvider {
  children: React.ReactNode;
  currentUser: CurrentUser;
  userId: string;
}

const CurrentUserContext = React.createContext<
  [CurrentUser | null, string | null]
>([null, null]);
const WebRolesConsumer = CurrentUserContext.Consumer;

const CurrentUserProvider: React.FC<CurrentUserProvider> = (
  props: CurrentUserProvider
) => {
  const Component = CurrentUserContext.Provider;

  return (
    <Component value={[props.currentUser, props.userId]}>
      {props.children}
    </Component>
  );
};

export { CurrentUserContext, CurrentUserProvider, WebRolesConsumer };
export default CurrentUserContext;
