import * as React from "react";
import {
  makeStyles,
  TextField,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { ThreadController, ThreadTags } from "../../types/Thread";
import { ThreadTitleBox } from "./ThreadTitleBox";
import { Message } from "../../models";
import { MessageBox } from "./MessageBox";
import classnames from "classnames";
import { VisibilityRadio } from "../VisibilityRadio";
import CurrentUserContext from "../../util/CurrentUserContext";

interface Props {
  thread: ThreadController;
  setSelected: (thread: ThreadController) => void;
  useComments?: boolean;
  smallerScreen: boolean;
  key: any;
  appliedTags: ThreadTags[];
  setAppliedTags: (tags: ThreadTags[]) => void;
  open: boolean;
  readonly: boolean;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "0 1rem",
    height: "fit-content",
    width: "inherit",
    maxWidth: "inherit",
    transition: "background-color 200ms",
  },
  selected: {
    backgroundColor: "#F9FAFA",
  },
  messagesContainer: {
    padding: "0.5rem",
  },
  textField: {
    flexGrow: 1,
    maxWidth: "100%",
    marginRight: "0.5rem",
  },
  text: {
    fontFamily: '"Raleway", sans-serif',
    fontSize: "1rem",
    fontWeight: "bold",
  },
  submitButton: {},
  inputBox: {
    display: "flex",
    paddingtop: "0.5rem",
    flexFlow: "row nowrap",
  },
  subText: {
    fontSize: "0.8rem",
    letterSpacing: "0.7px",
  },
});

export const ThreadGroup = ({
  thread,
  useComments,
  key,
  smallerScreen,
  appliedTags,
  open,
  ...props
}: Props) => {
  const classes = useStyles();
  const [messages, setMessages] = React.useState<Message[]>([
    ...thread.messages,
  ]);
  const [body, setBody] = React.useState<string>("");
  const ref = React.useRef<HTMLDivElement>();
  const currentUser = React.useContext(CurrentUserContext)[0];
  const currentUserIsPM = currentUser === "pm";
  const currentUserIsDesigner = currentUser === "agent";
  const { editMode } = thread;

  React.useEffect(() => {
    thread.onChange = () => {
      setMessages([...thread.messages]);
    };
  }, [thread]);

  React.useEffect(() => {
    if (thread.entity.selected && ref.current) {
      ref.current.scrollIntoView();
      const elem = document.getElementById(key);
      if (elem && !smallerScreen) {
        (elem as HTMLInputElement).focus();
      }
    }
  }, [thread.entity.selected]);

  const renderedMessages = React.useMemo(
    () => renderMessages(messages),
    [messages]
  );

  const createdAtDate = thread.createdAt
    ? new Intl.DateTimeFormat("default", {
        dateStyle: "short",
        timeStyle: "long",
      }).format(thread.createdAt.toDate())
    : null;

  return (
    <div
      ref={ref as any}
      key={key}
      className={classnames(
        classes.root,
        thread.entity.selected || open ? classes.selected : ""
      )}
    >
      <ThreadTitleBox
        thread={thread}
        onClick={() => props.setSelected(thread)}
        selected={thread.entity.selected || open}
        onDelete={() => thread.deleteSelf()}
        onEdit={() => {
          thread.editMode = true;
          thread.updateUi();
        }}
        readonly={props.readonly}
        appliedTags={appliedTags}
      />
      <div
        className={classes.messagesContainer}
        style={{ display: thread.entity.selected || open ? "block" : "none" }}
        // style={{ display: open || thread.entity.selected ? "block" : "none" }}
      >
        {(thread.entity.selected || open) && (
          <div
            style={{
              padding: !editMode ? "0.8rem" : "0",
              paddingBottom: "1.2rem",
            }}
          >
            {!editMode && (
              <>
                <Typography className={classes.text}>{thread.name}</Typography>
                {(currentUserIsPM || currentUserIsDesigner) && (
                  <Typography variant="caption" className={classes.subText}>
                    {createdAtDate}
                  </Typography>
                )}
              </>
            )}
          </div>
        )}
        {!editMode && renderedMessages}
        <form
          style={{ marginTop: "1rem" }}
          className={classes.inputBox}
          onSubmit={(e) => {
            e.preventDefault();
            thread.pushMessage(body.trim(), appliedTags);
            setBody("");
          }}
        >
          {thread.entity.selected &&
            useComments &&
            !editMode &&
            currentUserIsPM && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <TextField
                  className={classes.textField}
                  value={body}
                  placeholder="Additional notes..."
                  multiline
                  inputProps={{
                    id: key,
                  }}
                  autoFocus={!smallerScreen}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    setBody(value);
                  }}
                  helperText={currentUserIsPM ? null : `${body.length}/154`}
                />
                {!thread.tags.includes(ThreadTags.NOT_CLIENT_VISIBLE) && (
                  <VisibilityRadio
                    appliedTags={appliedTags}
                    setAppliedTags={props.setAppliedTags}
                  />
                )}
                <Button
                  color="primary"
                  disabled={body.length < 1}
                  variant="text"
                  type="submit"
                  style={{ height: "fit-content" }}
                  onClick={(e) => {
                    e.preventDefault();
                    thread.pushMessage(body.trim(), appliedTags);
                    setBody("");
                  }}
                >
                  Send
                </Button>
              </Box>
            )}
        </form>
      </div>
    </div>
  );

  function renderMessages(messages: Message[]) {
    let streak = "";

    const renderedMessages = [];
    for (const m of messages) {
      const current = `${m.authorId}${(m.tags ?? []).toString().trim()}`;

      renderedMessages.push(
        <MessageBox
          message={m}
          readonly={props.readonly}
          showUsername={streak !== current}
          key={m.body + m.id}
          onDelete={(id: number) => {
            thread.deleteMessage(id);
          }}
          onEdit={thread.updateMessage}
        />
      );

      if (streak !== current) {
        streak = current;
      }
    }

    return renderedMessages;
  }
};
