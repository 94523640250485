import heartIcon from "../../icons/Heart_Icon.png";
import changeIcon from "../../icons/Change_Icon.png";
import questionIcon from "../../icons/Question_Icon.png";
import removeIcon from "../../icons/Remove_Icon.png";
import pmIcon from "../../icons/PM_Icon.png";
import { ThreadTypes } from "../../types";

export function getIcon(type: ThreadTypes): string {
  switch (type) {
    case ThreadTypes.CHANGE:
      return changeIcon;
    case ThreadTypes.LOVE:
      return heartIcon;
    case ThreadTypes.REMOVE:
      return removeIcon;
    case ThreadTypes.QUESTION:
      return questionIcon;
    case ThreadTypes.PM:
      return pmIcon;
    default:
      throw new Error("An illegal thread type was passed to ThreadTitleBox");
  }
}
