import * as React from "react";
import { Message } from "../../models";
import {
  Divider,
  makeStyles,
  Avatar,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { MessagePartial, ThreadTags } from "../../types";
import EditMessageModal from "./EditMessageModal";
import CurrentUserContext from "../../util/CurrentUserContext";
import DFIcon from "../../icons/DesignerFacing.png";
import CFIcon from "../../icons/ClientFacing.png";

interface Props {
  onDelete: (id: number) => any;
  onEdit: (
    id: string | number,
    message: Pick<MessagePartial, "tags" | "body">
  ) => void;
  message: Message;
  showUsername?: boolean;
  key: string;
  readonly: boolean;
}

const useStyles = makeStyles({
  root: {
    margin: "0.3rem 0",
  },
  nameContainer: {
    flexFlow: "row nowrap",
    alignItems: "center",
    padding: "0 0.8rem",
    paddingTop: "0.5rem",
  },
  deleteButton: {
    marginLeft: "auto",
    cursor: "pointer",
    pointerEvents: "all",
  },
  bodyContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    marginTop: "10px",
  },
});

export const MessageBox = (props: Props) => {
  const classes = useStyles();
  const { message, key } = props;
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [currentUser, userId] = React.useContext(CurrentUserContext);
  const [del, setDel] = React.useState<boolean>(false);

  if (del) return null;

  function getAvatar() {
    if (currentUser === "agent" || currentUser === "pm") {
      return (
        <Avatar
          src={
            message.tags?.includes(ThreadTags.NOT_CLIENT_VISIBLE)
              ? DFIcon
              : CFIcon
          }
          style={{
            fontSize: "1rem",
            width: "25px",
            height: "25px",
          }}
        />
      );
    }

    return (
      <Avatar style={{ width: "25px", height: "25px" }} color="primary">
        {message.author[0]}
      </Avatar>
    );
  }

  return (
    <div key={key} className={classes.root}>
      <EditMessageModal
        open={editModalOpen}
        message={message}
        onSubmit={props.onEdit}
        onClose={() => setEditModalOpen(false)}
      />
      <div
        className={classes.nameContainer}
        style={{ display: props.showUsername ? "flex" : "none" }}
      >
        {getAvatar()}
        <Typography
          style={{
            fontSize: "1.1rem",
            marginLeft: "1rem",
          }}
        >
          {message.author}
        </Typography>
      </div>
      {props.showUsername && <Divider style={{ margin: "0.5rem 0.8rem" }} />}
      <div className={classes.bodyContainer}>
        <Typography
          style={{
            marginLeft: "0.8rem",
            marginTop: "0.2rem",
            flexGrow: 1,
            wordBreak: "break-word",
          }}
        >
          {message.body}
        </Typography>
        {(currentUser === "pm" ||
          (message.authorId === userId && !props.readonly)) && (
          <IconButton onClick={() => setEditModalOpen(true)}>
            <Edit />
          </IconButton>
        )}
        {(currentUser === "pm" ||
          (message.authorId === userId && !props.readonly)) && (
          <IconButton
            size="small"
            className={classes.deleteButton}
            onClick={handleMessageDelete}
          >
            <Delete />
          </IconButton>
        )}
      </div>
    </div>
  );

  function handleMessageDelete() {
    if (window.confirm("Delete message?")) {
      props.onDelete(message.id);
      setDel(true);
    }
  }
};
