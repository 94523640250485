import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import * as React from "react";
import { ThreadTags } from "../types";

interface IVisibilityRadioProps {
  appliedTags: ThreadTags[];
  setAppliedTags: (tags: ThreadTags[]) => void;
}

const VisibilityRadio: React.FunctionComponent<IVisibilityRadioProps> = ({
  appliedTags,
  setAppliedTags,
}) => {
  const radioValue = React.useMemo(
    () =>
      appliedTags.includes(ThreadTags.NOT_CLIENT_VISIBLE)
        ? "internal"
        : "public",
    [appliedTags]
  );

  return (
    <>
      <FormControlLabel
        label=""
        control={
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            paddingTop={2}
          >
            <FormLabel component="legend">Visibility</FormLabel>
            <RadioGroup
              aria-label="Visibility"
              name="Visibility"
              value={radioValue}
              onChange={(e) => {
                if (
                  e.target.checked &&
                  !appliedTags.includes(ThreadTags.NOT_CLIENT_VISIBLE)
                ) {
                  setAppliedTags([
                    ...appliedTags,
                    ThreadTags.NOT_CLIENT_VISIBLE,
                  ]);
                } else {
                  setAppliedTags(
                    appliedTags.filter(
                      (tag) => tag !== ThreadTags.NOT_CLIENT_VISIBLE
                    )
                  );
                }
              }}
            >
              <FormControlLabel
                value="internal"
                control={<Radio />}
                label="Note to Designer"
              />
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="Note to Client"
              />
            </RadioGroup>
          </Box>
        }
      />
    </>
  );
};

export { VisibilityRadio };
