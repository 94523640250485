import * as React from "react";
import {
  Annotator,
  Controller,
  FirebaseController,
  FirebaseControllerConfig,
  MuiCommentPane,
  ProfileProps,
  ThreadTypes,
  ThreadController,
  PopUp,
  Check,
} from "../";
import {
  Dialog,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import firebase from "firebase/compat";
import "firebase/compat/firestore";
import classnames from "classnames";
import { ThreadTags } from "../types";
import { CurrentUser, CurrentUserProvider } from "../util/CurrentUserContext";

type Media = any;
type Profile = any;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  annotatorContainer: {
    padding: "1rem 1rem",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
  },
  progress: {
    width: "inherit",
    position: "absolute",
    left: 0,
    transition: "opacity 300ms",
    height: "1rem",
  },
  progressHidden: {
    zIndex: -1,
    opacity: 0,
  },
  mobile: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "1rem",
    paddingTop: "3rem",
    height: "80vh",
    alignContent: "center",
    justifyContent: "center",
  },
  mobileHeader: {
    textAlign: "center",
    paddingBottom: "3rem",
    marginTop: "-2rem",
  },
}));

interface Props {
  media: Media[];
  open: boolean;
  user: firebase.User;
  profile: any;
  title?: string;
  exitButtonText?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  readonly?: boolean;
  currentUser: CurrentUser;
  useComments?: boolean;
  useAnnotationsFilter?: boolean;
  checks?: Check[];
  openToIndex?: number;
}

export interface AnnotatorPageProps extends Props {}

export const AnnotatorPage = (props: Props) => {
  const { user, profile } = props;
  const mobile = useMediaQuery((theme) =>
    (theme as Theme).breakpoints.down("xs")
  );

  const [annotationsFilter, setAnnotationsFilter] =
    React.useState<boolean>(false);

  const [index, setIndex] = React.useState<number>(props.openToIndex ?? 0);
  const [pageDimChange, setPageDimChange] = React.useState<number>(0);
  const [popupProps, setPopupProps] = React.useState<{
    open: boolean;
    top: number;
    left: number;
    message?: string;
    e?: MouseEvent;
  }>({
    open: false,
    top: 0,
    left: 0,
  });

  const [firebaseController, setFirebaseController] =
    React.useState<FirebaseController | null>(null);

  const [canvasController, setCanvascontroller] =
    React.useState<Controller | null>(null);

  const [annotator, setAnnotator] = React.useState<React.ReactNode | null>(
    null
  );

  const [appliedTags, setAppliedTags] = React.useState<ThreadTags[]>([]);

  const classes = useStyles();

  const onAnnotatorReady = (cb: () => Controller) => {
    setCanvascontroller(cb());
  };

  React.useEffect(listenForResizeEvents, []);

  React.useEffect(() => {
    if (canvasController) {
      canvasController.sizeCanvas();
    }
  }, [pageDimChange]);

  React.useEffect(() => {
    return () => {
      if (firebaseController) {
        firebaseController.destroy();
      }
    };
  }, []);

  React.useEffect(disableTouchMoveEffect, [open]);

  React.useEffect(() => {
    (async function () {
      if (annotationsFilter) {
        if (!(await testHasAnnotations(props.media[index]))) {
          handleChangeIndex(true);
        }
      }
    })();
  }, [annotationsFilter]);

  React.useEffect(() => {
    if (annotator) setAnnotator(undefined);
    setPopupProps({
      ...popupProps,
      open: false,
    });

    const config: FirebaseControllerConfig = {
      mediaId: props.media[index].id,
      profileProps: profile as Profile as ProfileProps,
      userId: (user as firebase.User).uid,
      currentUser: props.currentUser,
    };

    const _firebaseController = new FirebaseController(config);

    (async () => {
      const ready = _firebaseController.isReady();

      do {
        if (ready === true) {
          break;
        }

        if (ready instanceof Promise) {
          await ready;
          break;
        }

        throw new Error("isready is not working as intended");
      } while (false);

      if (firebaseController) firebaseController.destroy();
      setFirebaseController(_firebaseController);

      setAnnotator(
        <Annotator
          dataController={_firebaseController}
          onReady={onAnnotatorReady}
          readonly={props.readonly}
          onSelectThread={() => null}
          id={"canvas-" + index.toString()}
          onClick={(e: MouseEvent, thread: ThreadController | undefined) => {
            if (props.readonly) return;

            try {
              e.stopPropagation();
              (e as any).persist();
            } catch {
            } finally {
              const message = thread ? thread.name : undefined;

              setPopupProps({
                open: true,
                top: e.clientY,
                left: e.clientX,
                message,
                e: e,
              });
            }
          }}
        />
      );
    })();
  }, [index]);

  React.useEffect(() => {
    if (canvasController) canvasController.sizeCanvas();
  }, [Annotator]);

  if (mobile) {
    return (
      <div className={classes.mobile}>
        <Typography className={classes.mobileHeader} variant="h2">
          Hey There!
        </Typography>
        <Typography>
          Our Annotation tool is not ready for small screens yet.
        </Typography>
        <br />
        <Typography>
          Please come back to this screen on a device with a full sized screen
          and browser window!
        </Typography>
      </div>
    );
  }

  const height =
    Math.min(screen.availHeight, window.innerHeight).toString() + "px";

  return (
    <CurrentUserProvider userId={user.uid} currentUser={props.currentUser}>
      <Dialog open={props.open} fullScreen>
        <div
          className={classes.root}
          style={{
            height: height,
            maxHeight: height,
            width: `calc(${window.innerWidth}px - 280px)`,
            zIndex: 3,
          }}
        >
          <PopUp
            {...popupProps}
            appliedTags={appliedTags}
            setAppliedTags={setAppliedTags}
            onClose={() =>
              setPopupProps({
                ...popupProps,
                open: false,
                message: undefined,
              })
            }
            onClick={(type: ThreadTypes, name: string) => {
              canvasController &&
                canvasController.onClick(
                  popupProps.left,
                  popupProps.top,
                  type,
                  name,
                  appliedTags
                );
              setPopupProps({ ...popupProps, open: false, message: undefined });
            }}
          />
          <LinearProgress
            variant="indeterminate"
            className={classnames(
              classes.progress,
              annotator ? classes.progressHidden : ""
            )}
          />
          <div
            className={classes.annotatorContainer}
            style={{
              display: "inline-flex",
              width: "inherit",
              height: "inherit",
            }}
          >
            {annotator}
          </div>
          <MuiCommentPane
            checks={props.checks}
            readonly={!!props.readonly}
            ready={!!annotator}
            title={props.title || `Slide ${index + 1}/${props.media.length}`}
            noNav={props.media.length < 2}
            useAnnotationsFilter={props.useAnnotationsFilter}
            annotationsFilter={annotationsFilter}
            exitButtonText={props.exitButtonText}
            annotationsFilterDisabled={false}
            onAnnotationsFilterToggle={() =>
              setAnnotationsFilter(!annotationsFilter)
            }
            allowDelete={!props.readonly}
            allowEditAnnotations={!props.readonly}
            allowThreadDelete={!props.readonly}
            useComments={
              props.useComments ? props.useComments : !props.readonly
            }
            submitButton={
              props.readonly
                ? undefined
                : (((props.onSubmit as any) || undefined) as undefined)
            }
            exitButton={props.onClose}
            setSelected={(thread) => {
              if (canvasController) {
                canvasController.selectThread(thread);
              }
            }}
            setNext={() => handleChangeIndex(true)}
            setPrevious={() => handleChangeIndex(false)}
            dataController={firebaseController as FirebaseController}
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              height: window.innerHeight,
              width: "280px",
            }}
            appliedTags={appliedTags}
            setAppliedTags={setAppliedTags}
          />
        </div>
      </Dialog>
    </CurrentUserProvider>
  );

  async function handleChangeIndex(dir: boolean): Promise<void> {
    if (annotationsFilter) {
      return setIndex(await handleFilteredChangeIndex(dir));
    }

    if (!dir) {
      if (index > 0) {
        return setIndex(index - 1);
      }

      setIndex(props.media.length - 1);
    } else {
      if (index >= props.media.length - 1) {
        return setIndex(0);
      }

      setIndex(index + 1);
    }
  }

  async function handleFilteredChangeIndex(dir: boolean): Promise<number> {
    const len = props.media.length;

    if (!dir) {
      for (let i = index - 1; i !== index; i -= 1) {
        if (i < 0) {
          i = len;
          continue;
        }

        if (await testHasAnnotations(props.media[i])) {
          return i;
        }
      }

      return 0;
    }

    for (let i = index + 1; i !== index; i += 1) {
      if (i >= len) {
        i = -1;
        continue;
      }

      if (await testHasAnnotations(props.media[i])) {
        return i;
      }
    }

    return 0;
  }

  async function testHasAnnotations(media: Media): Promise<boolean> {
    const snap = await firebase
      .firestore()
      .collection("media")
      .doc(media.id)
      .collection("threads")
      .get();

    return !!(!snap.empty && snap.docs.length);
  }

  function disableTouchMoveEffect(): (() => void) | void {
    if (props.open) {
      document.ontouchmove = (e) => {
        e.preventDefault();
      };

      return () => {
        document.ontouchmove = null;
      };
    }
  }

  function listenForResizeEvents(): () => void {
    let timeout: any = null;

    window.addEventListener("resize", handleResize);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      window.removeEventListener("resize", handleResize);
    };

    function handleResize() {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        setPageDimChange(pageDimChange + 1);
      }, 500);
    }
  }
};
