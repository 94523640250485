// import { Drawable, Entities, Clickable } from "../types";
import { Entities } from "../types/Entities";
import { Clickable } from "../types/Clickable";

// TODO: merge this class with datapoint
export class Point implements Clickable {
  public x: number;
  public y: number;
  public radius: number;
  public selected = false;
  protected _color: string;

  public type: Entities;

  public constructor(x: number, y: number, radius: number, color?: string) {
    this.type = Entities.POINT;
    this.x = x;
    this.y = y;
    this.radius = radius;
    this._color = (color as string) || "#FFFFFF";
  }

  public get coordinates(): number[] {
    return [this.x, this.y];
  }

  public set color(color: string) {
    this._color = color;
  }

  public tick() {
    return;
  }

  public hitTest(x: number, y: number) {
    const hit =
      x > this.x - this.radius * 1.2 &&
      x < this.x + this.radius * 1.2 &&
      y > this.y - this.radius * 1.2 &&
      y < this.y + this.radius * 1.2;
    return hit;
  }

  public setSelected(on: boolean) {
    this.selected = on;
  }

  // public draw(ctx: CanvasRenderingContext2D): void {
  //   ctx.fillStyle = this._color;
  //   ctx.beginPath();
  //   ctx.ellipse(this.x, this.y, this.radius, this.radius, 0, 0, 2 * Math.PI);
  //   ctx.stroke();
  //   ctx.fill();
  // }
}
