import * as React from "react";
import {
  Box,
  Typography,
  makeStyles,
  Theme,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { CheckCircleOutline, Close } from "@material-ui/icons";

export interface ConfirmDeleteThreadProps {
  onConfirm: () => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    width: "100%",
  },
  confirmIcon: {
    color: "green",
  },
  cancelIcon: {
    color: "gray",
  },
}));

const ConfirmDeleteThread: React.FC<ConfirmDeleteThreadProps> = ({
  onConfirm,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box width="60%">
        <Typography>Really delete this comment?</Typography>
      </Box>
      <Box width="30%" display="flex" justifyContent="center">
        <Tooltip title="DELETE">
          <IconButton size="small" onClick={onConfirm}>
            <CheckCircleOutline className={classes.confirmIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip title="DON'T DELETE">
          <IconButton size="small" onClick={onClose}>
            <Close className={classes.cancelIcon} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export { ConfirmDeleteThread };
export default ConfirmDeleteThread;
