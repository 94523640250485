import { Messageable, MessagePartial, ThreadTags } from "../types";

export class Message implements Messageable {
  private _author: string;
  private _body: string;
  private _id: number; //set off index
  private _authorId: string;
  private _createdAt: number;
  private _seenBy: string[];
  private _modifiedAt: number;
  private _tags?: ThreadTags[];

  constructor(message: MessagePartial | Messageable) {
    this._authorId = message.authorId;
    this._author = message.author;
    this._body = message.body;
    this._id = message.id;
    this._seenBy = message.seenBy || [];
    this._createdAt =
      (message as Messageable).createdAt || new Date().getTime();
    this._modifiedAt =
      (message as Messageable).createdAt || new Date().getTime();
    this._tags = message.tags;
  }

  private _modify() {
    this._modifiedAt = new Date().getTime();
  }

  public get author() {
    return this._author;
  }

  public set author(author: string) {
    this._author = author;
    this._modify();
  }

  public get authorId() {
    return this._authorId;
  }
  public get id() {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
    this._modify();
  }
  public get body() {
    return this._body;
  }
  public set body(body: string) {
    this._body = body;
    this._modify();
  }
  public get seenBy(): string[] {
    return this._seenBy;
  }
  public get createdAt() {
    return this._createdAt;
  }
  public get modifiedAt() {
    return this._modifiedAt;
  }
  public get tags() {
    return this._tags;
  }
  public set seen(seenBy: string[]) {
    this._seenBy = seenBy;
    this._modify();
  }

  public serailize = () => ({
    id: this._id,
    author: this._author,
    authorId: this._authorId,
    body: this._body,
    seenBy: this._seenBy,
    createdAt: this._createdAt,
    modifiedAt: this._modifiedAt,
    tags: this._tags ?? [],
  });
}
