// import { Chattable, Entities, SerializedEntity, Drawable } from "../types";
import { Chattable } from "../types/Chattable";
import { Entities } from "../types/Entities";
import { SerializedEntity } from "../types/SerailizedEntity";
import { Drawable } from "../types/Drawable";
import { Point } from "./Point";
import { ThreadTypes } from "../types/Thread";
import getIcons, { Icons } from "../icons";

export interface SerializedDataPoint extends SerializedEntity {
  x: number;
  y: number;
  radius: number;
  color?: string;
}

export interface DataPointCreationProps {
  x: number;
  y: number;
  radius: number;
  color?: string;
  type?: Entities;
  selected?: boolean;
}
export class DataPoint
  extends Point
  implements Chattable, SerializedDataPoint, Drawable {
  selectedRadius: number;
  selectedSpacing: number;
  selected: boolean;
  icons?: Icons;
  // private _threadType: ThreadTypes;

  constructor(data: SerializedDataPoint | DataPointCreationProps) {
    super(data.x, data.y, data.radius, data.color || "#000000");
    this.type = Entities.DATA_POINT;
    this.selectedRadius = this.radius / 1.5;
    this.selectedSpacing = this.radius / 2;
    this.selected = !!(data as DataPointCreationProps).selected;

    getIcons().then((ico) => {
      this.icons = ico;
    });
  }

  tick(): any {
    return null;
  }

  private _iconSwitch(type: ThreadTypes): HTMLImageElement | null {
    switch (type) {
      case ThreadTypes.LOVE:
        return (this.icons as Icons).heart;
      case ThreadTypes.CHANGE:
        return (this.icons as Icons).change;
      case ThreadTypes.REMOVE:
        return (this.icons as Icons).remove;
      case ThreadTypes.QUESTION:
        return (this.icons as Icons).question;
      case ThreadTypes.PM:
        return (this.icons as Icons).pm;
      default:
        return null;
    }
  }

  draw(
    ctx: CanvasRenderingContext2D,
    type: ThreadTypes,
    maxX: number,
    maxY: number
  ) {
    if (!this.icons) {
      return;
    }

    const icon = this._iconSwitch(type);

    if (!icon) {
      return;
    }
    if (this.x > maxX) {
      this.x = maxX - this.radius / 2;
    }

    if (this.y > maxY) {
      this.y = maxY - this.radius / 2;
    }

    if (this.selected) {
      ctx.fillStyle = "#32a852";
      ctx.beginPath();
      ctx.ellipse(
        this.x + this.radius / 2,
        this.y + this.radius / 2,
        this.selectedRadius,
        this.selectedRadius,
        0,
        0,
        2 * Math.PI
      );
      ctx.stroke();
      ctx.fill();
    }

    ctx.drawImage(icon, this.x, this.y, this.radius, this.radius);

    // if (!this.selected) return;

    // ctx.fillStyle = "red";
    // ctx.beginPath();
    // ctx.ellipse(
    //   this.x + this.selectedSpacing,
    //   this.y - this.selectedSpacing,
    //   this.selectedRadius,
    //   this.selectedRadius,
    //   0,
    //   0,
    //   2 * Math.PI,
    // );
    // ctx.stroke();
    // ctx.fill();
  }

  public serialize = (): SerializedDataPoint => {
    return {
      type: this.type,
      x: this.x,
      y: this.y,
      radius: this.radius,
      color: this._color,
    };
  };
}
