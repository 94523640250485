import { Message } from "../models/Message";
import { Messageable, MessagePartial } from "./Messageable";
import { SerializedEntity } from "./SerailizedEntity";
import { Drawable } from "./Drawable";
import { Entities } from "./Entities";
import { DataPoint, SerializedDataPoint } from "../Entities/DataPoint";
import { Chattable } from "./Chattable";
import firebase from "firebase/compat";

export enum ThreadTypes {
  LOVE,
  REMOVE,
  CHANGE,
  QUESTION,
  PM,
}

export enum ThreadTags {
  NOT_CLIENT_VISIBLE = "notClientVisible",
}

export interface Thread {
  type: ThreadTypes;
  author: string;
  authorId: string | null;
  entity: SerializedEntity & Drawable;
  messages: Message[];
  name: string;
  tags?: ThreadTags[];
  createdAt: firebase.firestore.Timestamp;
}

export interface SerializedThread {
  type: ThreadTypes;
  author: string;
  authorId?: string;
  entity: SerializedEntity;
  name: string;
  tags?: ThreadTags[];
}

/*
  Controller definition for threads attached to points
  on the annotator.

  abstract methods marked below must be implemented to
  communicat with backend of choice
 */
export abstract class ThreadController implements Thread {
  public entity: SerializedEntity & Drawable & Chattable;
  public authorId: string;
  public name: string;
  public author: string;
  public _userId?: string;
  public type: ThreadTypes;
  public messages: Message[] = [];
  protected _userName: string;
  protected _onChange?: CallableFunction;
  public editMode: boolean = false;
  public tags: ThreadTags[];
  public createdAt: firebase.firestore.Timestamp;

  constructor(
    thread: Thread | SerializedThread,
    _userName: string,
    _userId: string
  ) {
    this._userName = _userName;
    this._userId = _userId;
    this.name = thread.name;
    this.type = thread.type;
    this.entity = (thread.entity as { draw?: any }).draw
      ? (thread.entity as Drawable & SerializedEntity)
      : this.makeEntityFromSerialized(thread.entity);
    this.author = thread.author;
    this.authorId = thread.authorId ?? "";
    this.tags = thread.tags ?? [];
    this.createdAt = (thread as Thread).createdAt;
    // TODO: make this cleaner and more efficient.

    if ((thread as Thread).messages) {
      this.messages =
        ((thread as Thread).messages &&
          this._sort(
            ((thread as Thread).messages as Messageable[]).map(
              (m: Messageable) => new Message(m)
            )
          )) ||
        [];
    }

    this._setAccentedStatus();
  }

  protected async _setAccentedStatus(yes?: boolean): Promise<any> {
    if (yes !== undefined) return (this.entity.selected = yes);
    this.entity.selected = this.checkMessagesSeen();
  }

  protected _sort(messages: Message[]): Message[] {
    return messages.sort((a, b) => (a.id < b.id ? -1 : 1));
  }

  protected _getNewMessageId(): number {
    return this.messages.length;
  }

  public abstract merge(props: SerializedThread | any): void;

  public updateUi() {
    if (this._onChange) {
      this._onChange(this.messages);
    }
  }

  public async setSeen() {
    let changed = false;
    this.messages.forEach((m) => {
      if (!m.seenBy.includes(this._userId || this._userName)) {
        m.seenBy.push(this._userId || this._userName);
        changed = true;
      }

      if (changed) this.save(true);
    });
    this._setAccentedStatus(false);
  }

  public set onChange(cb: ((m: Message[]) => any) | undefined) {
    if (cb instanceof Function || cb === undefined) this._onChange = cb;
    else throw new Error("onChange should be a function");
  }

  public abstract deleteSelf(): void;

  public serialize = (): SerializedThread => ({
    entity: (this.entity as any).serialize
      ? (this.entity as any).serialize()
      : this.entity,
    author: this.author,
    type: this.type,
    name: this.name,
    tags: this.tags,
    authorId: this._userId ?? "",
  });

  public makeEntityFromSerialized(
    e: SerializedEntity
  ): SerializedEntity & Drawable {
    switch (e.type) {
      case Entities.DATA_POINT:
        return new DataPoint(e as SerializedDataPoint);
      default:
        throw new Error("unrecognized serialized entity");
    }
  }

  public checkMessagesSeen(): boolean {
    return !!this.messages.find(
      (m) => !m.seenBy.includes(this._userId || this._userName)
    );
  }

  // implement as save to database method
  // must return this
  public abstract save(overwrite?: true): ThreadController;

  public abstract pushMessage(body: string, tags?: ThreadTags[]): void;

  public abstract deleteMessage(id: string | number): void;

  public abstract updateMessage(
    id: string | number,
    message: Pick<MessagePartial, "tags" | "body">
  ): void;
}
