import {
  ThreadController,
  SerializedThread,
  Thread,
  SerializedEntity,
  MessagePartial,
  ThreadTags,
} from "../types";
import { Message } from "../models/";
import firebase from "firebase/compat";
import "firebase/compat/firestore";
import { CurrentUser } from "../util/CurrentUserContext";

export class FirebaseThreadController extends ThreadController {
  private _threadDocRef: firebase.firestore.DocumentReference;
  private _lastDocSnapshot?: firebase.firestore.DocumentSnapshot;
  // private _lastMessageSnapshot?: firebase.firestore.QuerySnapshot;
  private _messageCollection: firebase.firestore.CollectionReference;
  private _unsubscribeHandlers: (() => void)[] = [];
  private currentUser: CurrentUser;
  public id: string;

  constructor(
    id: string,
    mediaId: string,
    userId: string,
    userName: string,
    thread: SerializedThread | Thread,
    currentUser: CurrentUser
  ) {
    super(thread, userName, userId);

    this.id = id;

    this.tags = thread.tags ?? [];
    this.currentUser = currentUser;

    this._threadDocRef = firebase
      .firestore()
      .collection("media")
      .doc(mediaId)
      .collection("threads")
      .doc(id);

    this._messageCollection = this._threadDocRef.collection("messages");

    this._listenForChanges();
  }

  public destroy() {
    for (let unsub of this._unsubscribeHandlers) unsub();
  }

  public save(): FirebaseThreadController {
    const s = this.serialize();

    for (const f of Object.values(s)) if (f === undefined) return this;

    this._threadDocRef.set(this.serialize(), { merge: true });
    return this;
  }

  private _listenForChanges() {
    let unsub = this._threadDocRef.onSnapshot((change) => {
      if (
        (change.exists &&
          this._lastDocSnapshot &&
          !change.isEqual(this._lastDocSnapshot)) ||
        !this._lastDocSnapshot
      ) {
        const data = change.data();

        if (data) {
          if (data.entity) {
            this.entity = this.makeEntityFromSerialized(
              data.entity as SerializedEntity
            );
          }
        }
      }
    });

    this._unsubscribeHandlers.push(unsub);

    unsub = this._messageCollection.onSnapshot((change) => {
      const changes = change.docChanges();

      let newMessages: Message[] = [];
      let updateMessages: Message[] = [];
      let deletedIds: number[] = [];

      for (let change of changes) {
        if (!this._messageIsVisible(change.doc.data() as Message)) {
          continue;
        }

        switch (change.type) {
          case "modified":
            updateMessages.push(
              new Message(change.doc.data() as MessagePartial)
            );
            break;
          case "added":
            newMessages.push(new Message(change.doc.data() as MessagePartial));
            break;
          case "removed":
            deletedIds.push(parseInt(change.doc.id));
            break;
          default:
            break;
        }
      }

      this._mergeMessages(updateMessages);
      this.messages.push(...newMessages);

      do {
        if (!!deletedIds.length && this.messages.length < 2) {
          this.messages = [];
          break;
        }

        deletedIds.forEach((id) => {
          const i = this.messages.findIndex((m) => m.id === id);
          if (i < 0) {
          } else {
            this.messages.splice(id, 1);
          }
        });
      } while (false);

      this.updateUi();
    });

    this._unsubscribeHandlers.push(unsub);
  }

  private _messageIsVisible(message: Message): boolean {
    if (
      !!message.tags &&
      message.tags.includes(ThreadTags.NOT_CLIENT_VISIBLE)
    ) {
      return this.currentUser === "pm" || this.currentUser === "agent";
    }

    return true;
  }

  protected _mergeMessages(m: Message[]): void {
    for (const message of m) {
      const old = this.messages.findIndex((me) => me.id === message.id);

      if (old >= 0) this.messages.splice(old, 1, message);

      if (!this.messages.some((m) => m.id === message.id)) {
        this.messages.push(message);
      }
    }
    this.messages = this._sort(this.messages);
  }

  public merge(props: SerializedThread | any): void {
    this._threadDocRef.set(props, { merge: true });
  }

  public pushMessage(body: string, tags: ThreadTags[] = []): void {
    const message = new Message({
      author: this._userName,
      authorId: this._userId,
      body,
      id: this._getNewMessageId(),
      userId: this._userId,
      seenBy: [this._userId],
      tags: [...this.tags, ...tags],
    } as MessagePartial);

    this._messageCollection.doc(message.id.toString()).set(message.serailize());
  }

  public deleteMessage = async (id: string | number): Promise<void> => {
    if (typeof id === "string") {
      id = parseInt(id);
    }

    const messageIn = this.messages.findIndex((m) => m.id === id);

    try {
      if (messageIn < 0) {
        throw new Error("could not find message to delete with id: " + id);
      }
    } catch (err) {
      console.error(err);
      return;
    }

    const res = this._messageCollection.doc(id.toString()).delete();
    await res;
  };

  public updateMessage = async (
    id: string | number,
    message: Pick<MessagePartial, "tags" | "body">
  ) => {
    if (typeof id === "string") {
      id = parseInt(id);
    }
    const messageIn = this.messages.findIndex((m) => m.id === id);

    try {
      if (messageIn < 0) {
        throw new Error("could not find message to update with id: " + id);
      }
    } catch (error) {
      console.error(error);
      return;
    }

    await this._messageCollection.doc(id.toString()).update({
      ...message,
    });
  };

  public deleteSelf = async (): Promise<void> => {
    const messageIds = await this._messageCollection.get();

    for await (const doc of messageIds.docs) {
      await this.deleteMessage(doc.id);
    }

    await this._threadDocRef.delete();
  };
}
