import { ThreadController, ThreadTypes } from "./Thread";
import { Drawable } from "./Drawable";
import { SerializedEntity } from "./SerailizedEntity";
import { ThreadTags } from ".";
// import { DataPoint, SerializedDataPoint } from "../Entities";

export abstract class DatabaseController {
  protected _threads: ThreadController[] = [];
  protected _initialized = false;
  protected _initializedPromise?: Promise<any>;

  // public abstract onThreadChange(): void
  protected abstract deserializeThreads(threads: any): ThreadController[];

  // set _initialized to true when this is complete
  protected abstract _initialize(config: any): any;

  protected loadThreads(threads: any): void {
    this._threads = this.deserializeThreads(threads);
  }

  public abstract getSrc(): string;

  public save(): void {
    for (const thread of this._threads) [thread.save()];
  }

  public get threads(): ThreadController[] {
    return this._threads;
  }

  public abstract updateUi(): void;

  public abstract newThread(
    entity: SerializedEntity & Drawable,
    type: ThreadTypes,
    name: string,
    tags?: ThreadTags[]
  ): ThreadController;
}
