import * as React from "react";
import { DataPoint } from "./Entities/DataPoint";
import {
  DatabaseController,
  ThreadController,
  ThreadTags,
  ThreadTypes,
} from "./types";

export enum PointerMode {
  MAKE_POINT,
  DEFAULT,
}

export class Controller {
  private _containerRef: React.MutableRefObject<any>;
  private _stopped = false;
  private _canvasRef: React.MutableRefObject<any>;
  private _image: HTMLImageElement;
  private _selected: ThreadController | null = null;
  private _dataController: DatabaseController;
  private _threadTypeFilters: ThreadTypes[] = [];
  private _hideAnnotations = false;
  private _sizeTimeout: any;
  private _mouseOver: boolean = false;
  private _mouseChaser?: HTMLDivElement;
  private _readonly?: boolean;
  // private _mouseMoveTimeout?: any;
  // private _queMouseMoveTimeout = false;

  private _mode: PointerMode = PointerMode.MAKE_POINT;

  public onSelect?: (thread?: ThreadController) => any;

  constructor(
    dataController: DatabaseController,
    canvasRef: React.MutableRefObject<any>,
    containerRef: React.MutableRefObject<any>,
    image: HTMLImageElement,
    readonly?: boolean
  ) {
    this._readonly = readonly;
    this._dataController = dataController;
    this._canvasRef = canvasRef;
    this._containerRef = containerRef;
    this._image = image;
    this._getCanvas().width = this._imageWidth;
    this._getCanvas().height = this._imageHeight;

    this._resize();
    window.addEventListener("resize", this._resize);

    this._draw();
  }

  private _resize = () => {
    if (!this._getCanvas()) return;
    this.sizeCanvas();
    if (
      this._isSmallerScreen() &&
      this._getCanvas().style.cursor !== "default"
    ) {
      this._getCanvas().style.cursor = "default";
    }
  };

  public destroy(): void {
    if (this._sizeTimeout) clearTimeout(this._sizeTimeout);
    this._stopped = true;
    window.removeEventListener("resize", this._resize);
  }

  private _getCanvas = (): HTMLCanvasElement => this._canvasRef.current;

  private _getCtx = () => {
    if (!this._getCanvas()) return null;
    return this._getCanvas().getContext("2d") as CanvasRenderingContext2D;
  };

  private get _imageHeight(): number {
    return this._image.naturalHeight;
  }

  private get _imageWidth(): number {
    return this._image.naturalWidth;
  }

  private _calculateHeightFromWidth(width: number): number {
    return (this._imageHeight / this._imageWidth) * width;
  }

  private _isSmallerScreen(): boolean {
    return window.innerWidth < 1280;
  }

  public getRelativePixelValue = (px: number): number => {
    const iw =
      this._imageWidth > this._imageHeight
        ? this._imageWidth
        : this._imageHeight;

    const sw = this._imageWidth > this._imageHeight ? 1600 : 1000;
    return iw * (px / sw);
  };

  private _calculateWidthFromHeight(height: number): number {
    return (this._imageWidth / this._imageHeight) * height;
  }

  private _draw = (): any => {
    if (this._stopped) return;

    const ctx = this._getCtx();

    if (!ctx) return requestAnimationFrame(this._draw);

    ctx.clearRect(0, 0, this._getCanvas().width, this._getCanvas().height);

    ctx.drawImage(
      this._image,
      0,
      0,
      this._getCanvas().width,
      this._calculateHeightFromWidth(this._getCanvas().width)
    );

    do {
      if (this._hideAnnotations) break;

      const filterOn = !!this._threadTypeFilters.length;

      const maxY = this._imageHeight;
      const maxX = this._imageWidth;

      for (const t of this._dataController.threads) {
        if (filterOn && this._threadTypeFilters.includes(t.type)) continue;

        t.entity.tick();
        t.entity.draw(ctx, t.type, maxX, maxY);
      }
    } while (false);

    requestAnimationFrame(this._draw);
  };

  private _getRealXy = (e: MouseEvent): number[] => {
    const rect = this._getCanvas().getBoundingClientRect();

    const x: number =
      ((((e.clientX - rect.left) / rect.height) *
        this._imageHeight) as number) || 0;
    const y: number =
      ((((e.clientY - rect.top) / rect.width) * this._imageWidth) as number) ||
      0;

    return [x, y];
  };

  private _getRealXyCart = (clientX: number, clientY: number): number[] => {
    const rect = this._getCanvas().getBoundingClientRect();

    const x: number =
      ((((clientX - rect.left) / rect.height) * this._imageHeight) as number) ||
      0;
    const y: number =
      ((((clientY - rect.top) / rect.width) * this._imageWidth) as number) || 0;

    return [x, y];
  };

  public onClick = (
    clientX: number,
    clientY: number,
    type: ThreadTypes,
    name: string,
    tags: ThreadTags[] = []
  ): void => {
    // convert MAKE_POINT location relative to canvas position to poin relative to image natural size //
    const [x, y] = this._getRealXyCart(clientX, clientY);

    if (this.mode === PointerMode.MAKE_POINT) {
      if (!this.hitTest(x, y)) {
        const adjustedRadius = this.getRelativePixelValue(40);
        const point = new DataPoint({
          x: x - adjustedRadius / 2,
          y: y - adjustedRadius / 2,
          radius: this.getRelativePixelValue(40),
        });

        // TODO: Implement Real Name;
        const thread = this._dataController.newThread(point, type, name, tags);

        this.selectThread(thread);
      }
    } else if (this.mode === PointerMode.DEFAULT) {
      const clicked = this.hitTest(x, y);
      if (!clicked) return this._deselect();

      this.selectThread(clicked);
      this._dataController.updateUi();
      clicked.updateUi();
    }
  };

  public hitTest(x: number, y: number): ThreadController | undefined {
    return this._dataController.threads.find(
      (d) => !!(d.entity as any).hitTest && !!(d.entity as any).hitTest(x, y)
    ) as ThreadController | undefined;
  }

  public isOverPoint = (x: number, y: number): ThreadController | undefined => {
    const [rx, ry] = this._getRealXyCart(x, y);
    return this.hitTest(rx, ry);
  };

  private _deselect = (): void => {
    if (this._selected) {
      (this._selected.entity as any).setSelected(false);

      this._selected.updateUi();
      this._selected = null;

      if (this.onSelect) {
        this.onSelect(undefined);
      }
    }
  };

  // TODO: move thread selection to data controller
  public selectThread = (thread: ThreadController, hover = false): void => {
    if (this._selected) {
      if (this._selected === thread && !hover) {
        const sel = this._selected;
        this._deselect();
        sel.updateUi();
        return;
      }
      this._deselect();
    }

    (thread.entity as any).setSelected(true);
    this._selected = thread;
    this._selected.updateUi();

    if (this.onSelect) {
      this.onSelect(this._selected);
    }
  };

  private _handleMouseOver = (e: MouseEvent) => {
    if (this._isSmallerScreen()) {
      return;
    }

    const [x, y] = this._getRealXy(e);
    const thread = this.hitTest(x, y);

    if (!thread) {
      if (!this._readonly && this._getCanvas().style.cursor !== "crosshair") {
        this._getCanvas().style.cursor = "crosshair";
      }

      if (this._mouseChaser && !this._readonly) {
        const p = this._mouseChaser.children.item(0) as HTMLParagraphElement;
        if (p.innerText !== "click to add a comment") {
          p.innerText = "click to add a comment";
        }
      }

      return;
    }

    if (thread === this._selected) {
      if (!this._readonly && this._mouseChaser) {
        const p = this._mouseChaser.children.item(0) as HTMLParagraphElement;
        if (p.innerText !== "Click to view comment") {
          p.innerText = "Click to view comment";
        }
      }

      if (this._getCanvas().style.cursor !== "pointer") {
        this._getCanvas().style.cursor = "pointer";
      }

      return;
    }

    this.selectThread(thread, true);
  };

  private _setMouseMoveTimeout(e: MouseEvent) {
    // TODO: write limiting logic
    this._handleMouseOver(e);
  }

  public onMouseEnter = (e: MouseEvent): void => {
    if (this._isSmallerScreen()) {
      return;
    }

    this._mouseOver = true;

    if (!this._readonly && this._containerRef.current) {
      if (this._mouseChaser) {
        this._containerRef.current.removeChild(this._mouseChaser);
        this._mouseChaser = undefined;
      }

      const div = document.createElement("div");
      div.id = "mouse-chaser";
      div.style.position = "absolute";
      div.style.backgroundColor = "white";
      div.style.zIndex = "9";
      div.style.padding = "0 8px";

      const p = document.createElement("p");
      p.style.fontSize = "12px";
      p.style.fontFamily = '"Raleway", sans-serif';
      p.innerText = "click to add a comment";

      this._mouseChaser = div;
      this._setMouseChaserPosition(e);

      div.appendChild(p);
      this._containerRef.current.appendChild(div);
    }
  };

  public onMouseLeave = (): void => {
    if (this._isSmallerScreen()) {
      return;
    }

    this._mouseOver = false;

    if (this._mouseChaser && this._containerRef.current) {
      (this._mouseChaser.parentElement as HTMLDivElement).removeChild(
        this._mouseChaser
      );
      this._mouseChaser = undefined;
    }
  };
  public onMouseMove = (e: MouseEvent): void => {
    if (this._isSmallerScreen()) {
      return;
    }

    (e as any).persist();

    if (!this._mouseChaser || !this._mouseOver) {
      this.onMouseEnter(e);
    }
    if (!this._mouseOver) {
      return;
    }

    this._setMouseChaserPosition(e);
    this._setMouseMoveTimeout(e);
  };

  private _setMouseChaserPosition(e: MouseEvent) {
    if (this._isSmallerScreen()) {
      return;
    }

    if (!this._readonly && this._mouseChaser && this._containerRef.current) {
      this._mouseChaser.style.left = (e.clientX + 30).toString() + "px";
      this._mouseChaser.style.top = (e.clientY - 50).toString() + "px";
    }
  }

  public getHandlers = (): any => {
    return {
      onClick: this.onClick,
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
      onMouseMove: this.onMouseMove,
    };
  };

  public sizeCanvas = () => {
    if (this._sizeTimeout) clearTimeout(this._sizeTimeout);

    this._sizeTimeout = setTimeout(() => {
      const maxHeight = window.outerHeight - 150;
      const maxWidth = this._calculateWidthFromHeight(maxHeight);
      const h = this.getHeight();

      if (h === 0) {
        return this.sizeCanvas();
      }

      this._getCanvas().style.height = h + "px";
      this._getCanvas().style.maxHeight = maxHeight + "px";
      this._getCanvas().style.maxWidth = maxWidth + "px";
    }, 200);
  };

  public get selectedThreadController(): ThreadController | null {
    return this._selected;
  }

  public getHeight = (): number => {
    if (!this._getCanvas()) {
      return 0;
    }

    const h = this._calculateHeightFromWidth(
      this._getCanvas().getBoundingClientRect().width
    );

    return h;
  };

  public get mode(): PointerMode {
    return this._mode;
  }

  public set mode(mode: PointerMode) {
    if (this._mode === PointerMode.DEFAULT && mode !== PointerMode.DEFAULT)
      this._deselect();

    switch (mode) {
      case PointerMode.DEFAULT:
      case PointerMode.MAKE_POINT:
        if (!this._isSmallerScreen()) {
          this._getCanvas().style.cursor = "pointer";
        }

        break;
    }

    this._mode = mode;
  }
}
