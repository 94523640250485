import * as React from "react";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import {
  DatabaseController,
  ThreadController,
  ThreadTags,
  ThreadTypes,
} from "../../types";
import {
  makeStyles,
  Typography,
  Divider,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Theme,
  Box,
} from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import classnames from "classnames";
import { ThreadGroup } from "./ThreadGroup";
import { FirebaseController } from "../../FirebaseController";
import FilterBar, { TypesSelected } from "./FilterBar";

export interface Check {
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange: (...args: any[]) => void;
}

interface Props extends PaperProps {
  exitButton?: (args: any) => any;
  submitButton?: () => void;
  exitButtonText?: string;
  globalTypographyClass?: string;
  style: React.CSSProperties;
  dataController: DatabaseController;
  title: string;
  noNav?: boolean;
  allowDelete?: boolean;
  useAnnotationsFilter?: boolean;
  onAnnotationsFilterToggle: () => void;
  annotationsFilterDisabled?: boolean;
  annotationsFilter?: boolean;
  allowThreadDelete?: boolean;
  ready: boolean;
  useFilter?: boolean;
  useComments?: boolean;
  allowEditAnnotations?: boolean;
  setSelected: (thread: ThreadController) => void;
  setNext: () => void;
  setPrevious: () => void;
  checks?: Check[];
  appliedTags: ThreadTags[];
  setAppliedTags: (tags: ThreadTags[]) => void;
  readonly: boolean;
}

type MuiCommentPaneProps = Props;

const useStyles = makeStyles({
  buttonText: {
    color: "black !important",
  },
  root: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    borderRadius: "0px",
  },
  checkContainer: {
    padding: "1rem",
  },
  titleContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    paddingBottom: "16px",
    paddingTop: "8px",
  },
  title: {
    fontSize: "2rem",
    margin: "auto",
    padding: "0.5rem 0",
  },
  threadsBox: {
    display: "flex",
    padding: "8px 0",
    flexFlow: "column nowrap",
    flexGrow: 1,
    overflowY: "auto",
  },
  buttonPad: {
    minHeight: "40px",
  },
  font: {
    fontFamily: '"Raleway", sans-serif',
  },
});

export const MuiCommentPane = ({
  ready,
  dataController,
  globalTypographyClass,
  setSelected,
  title,
  useFilter,
  useComments,
  exitButton,
  submitButton,
  allowEditAnnotations,
  allowDelete,
  noNav,
  exitButtonText,
  appliedTags = [],
  setAppliedTags,
  ...props
}: MuiCommentPaneProps) => {
  const [threads, setThreads] = React.useState<ThreadController[]>(
    (dataController && dataController.threads) || []
  );
  const [viewAll, setViewAll] = React.useState<boolean>(false);
  const smallerScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  React.useEffect(() => {
    if (dataController) {
      setThreads(dataController.threads);
    }
  }, [dataController]);

  const [filters, setFilters] = React.useState<TypesSelected>({});

  const setFilter = (type: ThreadTypes) => {
    let newFilter: TypesSelected = {};

    switch (type) {
      case ThreadTypes.CHANGE:
        newFilter = { change: !filters.change };
        break;
      case ThreadTypes.LOVE: //
        newFilter = { love: !filters.love };
        break;
      case ThreadTypes.REMOVE:
        newFilter = { remove: !filters.remove };
        break;
      case ThreadTypes.QUESTION:
        newFilter = { question: !filters.question };
        break;
      default:
        throw new Error("An illegal thread type was passed to filter select");
    }

    setFilters({ ...filters, ...newFilter });
  };

  const threadFilter = (thread: ThreadController): boolean => {
    if (
      !filters.love &&
      !filters.change &&
      !filters.question &&
      !filters.remove
    ) {
      return true;
    }

    switch (thread.type) {
      case ThreadTypes.CHANGE:
        if (filters.change) return true;
        break;
      case ThreadTypes.LOVE:
        if (filters.love) return true;
        break;
      case ThreadTypes.REMOVE:
        if (filters.remove) return true;
        break;
      case ThreadTypes.QUESTION:
        if (filters.question) return true;
        break;
    }
    return false;
  };

  React.useEffect(() => {
    if (!dataController) {
      setThreads([]);
      return;
    }

    (dataController as FirebaseController).onChange = () =>
      setThreads([...dataController.threads]);
  }, [dataController]);

  const classes = useStyles();

  return (
    <Paper {...props} className={classes.root} classes={{ root: classes.root }}>
      <div className={classes.titleContainer}>
        {ready && !noNav && (
          <IconButton onClick={props.setPrevious} disabled={!dataController}>
            <ArrowLeft />
          </IconButton>
        )}
        <Typography
          className={classnames(
            classes.title,
            classes.font,
            globalTypographyClass || ""
          )}
        >
          {title}
        </Typography>
        {ready && !noNav && (
          <IconButton onClick={props.setNext} disabled={!dataController}>
            <ArrowRight />
          </IconButton>
        )}
      </div>
      <Box pb={2} display="flex" justifyContent="center">
        <Button
          color={viewAll ? "primary" : undefined}
          variant={!!viewAll ? "contained" : "text"}
          onClick={() => setViewAll(!viewAll)}
        >
          View All
        </Button>
      </Box>
      <Divider></Divider>
      {threads ? (
        <div className={classes.threadsBox}>
          {threads
            .filter(threadFilter)
            .sort((a, b) => (a.type > b.type ? 1 : -1))
            .map((t) => {
              const key = JSON.stringify(t.serialize());
              return (
                <div key={key}>
                  <ThreadGroup
                    open={viewAll}
                    smallerScreen={smallerScreen}
                    thread={t}
                    key={key}
                    setSelected={setSelected}
                    useComments={useComments}
                    appliedTags={appliedTags}
                    setAppliedTags={setAppliedTags}
                    readonly={props.readonly}
                  />
                  <Divider />
                </div>
              );
            })}
        </div>
      ) : null}
      {renderCheckBoxes()}
      {useFilter && <FilterBar onSelectType={setFilter} selected={filters} />}
      {submitButton && (
        <div
          style={{ display: "flex", flexFlow: "column nowrap" }}
          className={classes.buttonPad}
        >
          <Divider></Divider>
          <Button
            // set background color using inline styles because primary & secondary colors do not match theme of page
            style={{
              backgroundColor: "#BFCCBF",
            }}
            variant="contained"
            classes={{ text: classes.buttonText }}
            onClick={submitButton}
            className={classes.buttonPad}
          >
            I'm done
          </Button>
        </div>
      )}
      {exitButton && (
        <div
          style={{ display: "flex", flexFlow: "column nowrap" }}
          className={classes.buttonPad}
        >
          <Divider></Divider>
          <Button
            variant="text"
            classes={{ text: classes.buttonText }}
            onClick={exitButton}
            color="primary"
          >
            {getExitButtonText()}
          </Button>
        </div>
      )}
    </Paper>
  );

  function getExitButtonText(): string {
    if (exitButtonText) {
      return exitButtonText;
    }

    const text = !submitButton ? "Exit" : "Finish later";

    return text;
  }

  function renderCheckBoxes(): React.ReactNode[] {
    const components: React.ReactNode[] = [];

    if (props.checks) {
      props.checks.forEach((c, i) =>
        components.push(
          <div key={`${c.value}-${i}`} className={classes.checkContainer}>
            {!!i && <Divider />}
            <FormControlLabel
              label={c.label}
              disabled={c.disabled}
              control={
                <Checkbox
                  color="primary"
                  disabled={c.disabled}
                  checked={c.value}
                  onChange={c.onChange}
                />
              }
            />
          </div>
        )
      );
    }

    if (props.useAnnotationsFilter) {
      components.push(
        <div className={classes.checkContainer}>
          <Divider />
          <FormControlLabel
            label="Filter Annotated Slides"
            disabled={props.annotationsFilterDisabled}
            control={
              <Checkbox
                checked={props.annotationsFilter}
                color="primary"
                onChange={props.onAnnotationsFilterToggle}
              />
            }
          />
        </div>
      );
    }

    return components;
  }
};
