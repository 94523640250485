import heartIcon from "./icons/Heart_Icon.png";
import changeIcon from "./icons/Change_Icon.png";
import questionIcon from "./icons/Question_Icon.png";
import removeIcon from "./icons/Remove_Icon.png";
import pmIcon from "./icons/PM_Icon.png";
import dfIcon from "./icons/DesignerFacing.png";
import cfIcon from "./icons/ClientFacing.png";

export {
  heartIcon,
  changeIcon,
  questionIcon,
  removeIcon,
  pmIcon,
  dfIcon,
  cfIcon,
};

export interface Icons {
  heart: HTMLImageElement;
  change: HTMLImageElement;
  question: HTMLImageElement;
  remove: HTMLImageElement;
  pm: HTMLImageElement;
  df: HTMLImageElement;
  cf: HTMLImageElement;
}

// heart: HTMLImageElement;
// change: HTMLImageElement;
// question: HTMLImageElement;
// remove: HTMLImageElement;

let ready = false;
let icons: Icons;

let iPromise: Promise<Icons> | null;

const loadImage = async (src: string): Promise<HTMLImageElement> => {
  const img = new Image();

  const imgPromise = new Promise((resolve) => {
    img.onload = () => {
      resolve(0);
    };
  });

  img.src = src;
  await imgPromise;

  return img;
};

export default async (): Promise<Icons> => {
  if (ready) {
    return icons as Icons;
  }

  if (iPromise) {
    return iPromise;
  }

  iPromise = (async (): Promise<Icons> => {
    return {
      heart: await loadImage(heartIcon),
      change: await loadImage(changeIcon),
      question: await loadImage(questionIcon),
      remove: await loadImage(removeIcon),
      pm: await loadImage(pmIcon),
      df: await loadImage(dfIcon),
      cf: await loadImage(cfIcon),
    };
  })();

  icons = await iPromise;
  ready = true;
  iPromise = null;
  return icons;
};
